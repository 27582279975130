import { object, string, ref } from 'yup'
import { differenceInYears } from 'date-fns'
import emojiRegex from 'emoji-regex'
import { PASSWORD_REGEX } from '~/utils/constants'

export const registrationFormSchema = object().shape({
  civility: string().required('Votre civilité est requise.'),
  lastname: string().required('Votre nom est requis.'),
  firstname: string().required('Votre prénom est requis.'),
  email: string()
    .required('Votre adresse e-mail est requise.')
    .email('Votre adresse e-mail est incorrecte.'),
  password: string()
    .required('Votre mot de passe est requis.')
    .matches(
      PASSWORD_REGEX,
      'Caractères spéciaux autorisés : _@$€!={}()%#^+*?&-./\\<>',
    ),
  password_confirmation: string().equals(
    [ref('password')],
    'Les mots de passe doivent correspondre',
  ),
  nickname: string().required('Votre pseudo est requis'),
  birthday: string()
    .required('Votre date de naissance est requise.')
    .test(
      'birthday-check',
      'Désolé, vous devez avoir 13 ans minimum pour pouvoir vous inscrire.',
      (value) => differenceInYears(new Date(), new Date(value)) >= 13,
    ),
})

export const updateProfileFormSchema = object().shape({
  civility: string().required('Votre civilité est requise.'),
  firstname: string().required('Votre nom est requis.'),
  lastname: string().required('Votre prénom est requis.'),
  nickname: string().required('Votre pseudo est requis'),
  birthday: string()
    .required('Votre date de naissance est requise.')
    .test(
      'birthday-check',
      'Désolé, vous devez avoir 13 ans minimum.',
      (value) => differenceInYears(new Date(), new Date(value)) >= 13,
    ),
})

export const passwordUpdateFormSchema = object().shape({
  current_password: string().required('Votre ancien mot de passe est requis'),
  password: string()
    .required('Votre nouveau mot de passe est requis.')
    .matches(
      PASSWORD_REGEX,
      'Caractères spéciaux autorisés : _@$€!={}()%#^+*?&-./\\<>',
    ),
  password_confirmation: string().oneOf(
    [ref('password')],
    'Les mots de passe doivent correspondre',
  ),
})

const regex = emojiRegex()

export const passwordResetFormSchema = object().shape({
  password: string()
    .required('Votre mot de passe est requis.')
    .matches(
      PASSWORD_REGEX,
      'Caractères spéciaux autorisés : _@$€!={}()%#^+*?&-./\\<>',
    ),
  password_confirmation: string().oneOf(
    [ref('password')],
    'Les mots de passe doivent correspondre',
  ),
})

export const reviewSchema = string()
  .min(
    140,
    (msg) =>
      `Il manque ${msg.min - msg.value.length} caractère${
        msg.min - msg.value.length > 1 ? 's' : ''
      }`,
  )
  .test('emoji', 'Les emojis ne sont pas autorisés', (value) => {
    return value?.match(regex) === null
  })

export const titleSchema = string()
  .max(50, 'Le titre de votre avis doit faire 50 caractères maximum')
  .test('emoji', 'Les emojis ne sont pas autorisés', (value) => {
    return value?.match(regex) === null
  })
